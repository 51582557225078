import landing from './landing.vue';

export default [
  {
    path: '/llama-ai',
    name: 'Llama AI',
    component: landing,
    meta: {
      title: 'Llama AI',
    },
  },
];
