// src/custom_dashboard_data.js
const datas = [
  {
    title: 'Fertility in Nigeria',
    description: 'Monitors the fertility rate of women in states in Nigeria.',
    visibility: 'private',
  },
  {
    title: 'Mortality Rate in Nigeria',
    description: 'Monitors the mortality rate in various states in Nigeria.',
    visibility: 'private',
  },
  {
    title: 'Mortality in Nigeria',
    description: 'Monitors the mortality rate in various states in Nigeria.',
    visibility: 'public',
  },
  {
    title: 'Fertility in Nigeria',
    description: 'Monitors the fertility rate of women in states in Nigeria.',
    visibility: 'private',
  },
];

export default datas;
